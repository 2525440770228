<template>
  <div class="geofeaturelist">
    <h1 class="f3 fw5 tracked">
      Geofeatures
      <span v-if="geofeatures" class="f5">({{ geofeatures.length }})</span>
      <SearchBox
        class="fr f6"
        :searchList="geofeatures"
        :linkBase="channelID + '/geofeatures'"
      />
    </h1>
    <router-link to="">
      <span>
        <button
          @click="performDelete"
          v-if="checkedIDs.length"
          type="button"
          id="trash"
          class="mr1"
          style="
            background: url(/font-awesome-svgs/trash-alt.svg);
            background-size: 18px 18px;
            width: 18px;
            height: 18px;
            border: 0px;
            margin-left: 5px;
          "
        />

        <span @click="performDelete" v-if="checkedIDs.length" class="mr4">
          Delete Selected
          <span class="f5">({{ checkedIDs.length }})</span>
        </span>
      </span>
    </router-link>
    <div id="tableset" class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <thead>
          <tr class="bg-gold b">
            <th>
              <input
                type="checkbox"
                class
                v-model="deleteAllCheckBox"
                @change="toggleAllchecked($event)"
              />
            </th>
            <th class="th-sm">Name/ID</th>
            <th>Description</th>
            <th>Type</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="geofeature in this.geofeatures" :key="geofeature.ID">
            <td>
              <input
                type="checkbox"
                :id="geofeature.ID"
                :value="geofeature.ID"
                v-model="checkedIDs"
                :disabled="
                  getSessionObj.userID === geofeature.OwnerID ||
                  isAdmin(getSessionObj.userID)
                    ? false // if owner or admin, don't disable the checkbox
                    : 'disabled'
                "
              />
            </td>
            <td>
              <router-link
                class="gray hover-gold"
                :to="channelID + '/geofeatures/' + geofeature.ID"
              >
                {{ geofeature.Name }}
                <div class="f7">{{ geofeature.ID }}</div>
              </router-link>
            </td>
            <td>{{ geofeature.Description }}</td>
            <td>{{ geofeature.Geometry.Type }}</td>
            <td>
              <JSONViewer :json="geofeature.Data" label="Geofeature Data" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import { mapGetters } from "vuex";
import SearchBox from "@/components/SearchBox.vue";
import JSONViewer from "@/components/JSONViewer.vue";
import { messageBus } from "@/main.js";

export default {
  name: "GeofeatureList",
  data() {
    return {
      geofeatures: [],
      parseCsv: [],
      deleteAllCheckBox: false,
      checkedIDs: [],
    };
  },
  props: {
    channelID: String,
  },
  computed: mapGetters(["getSessionObj"]),
  methods: {
    canEdit() {
      // you can edit if the channel is
      //  (!channelLocked) AND (youAreOwner || youAreAdmin)
      const youAreOwner =
        this.getSessionObj.userID === this.channel.OwnerID ? true : false;
      const youAreAdmin = Helpers.isAdmin();

      if (!this.channel.IsLocked && (youAreOwner || youAreAdmin)) {
        return true;
      }
      return false;
    },
    fetchGeofeatures(channelID) {
      console.log("fetching channel with id: ", channelID);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      // let url = this.getSessionObj.apiURL;
      console.log("/channels/" + channelID + "/geofeatures");
      this.$store
        .dispatch("apiGetData", {
          url: "/channels/" + channelID + "/geofeatures",
          commit: false,
          errorAlert: "ERROR: Could not retrieve geofeatures for channel",
        })
        .then((response) => {
          this.geofeatures = [];
          this.checkedIDs = [];
          this.deleteAllCheckBox = false;

          this.$nextTick(() => {
            this.geofeatures = response.Items;
            console.log("Geofeatures:");
            console.log(this.geofeatures);
          });
        });
    },
    performDelete() {
      this.$bvModal
        .msgBoxConfirm("Are you sure?  This action can not be undone.")
        .then((value) => {
          console.log("DELETE CONFIRMATION VALUE: ", value);

          if (!value) {
            return;
          }

          this.deleteGeofeatures(this.checkedIDs);

          this.checkedIDs = [];
          if (this.deleteAllCheckBox) {
            this.deleteAllCheckBox = false;
          }
        })
        .catch((err) => {
          // An error occurred
          // skip delete
          console.log(err);
        });
    },
    deleteGeofeatures(idArray) {
      console.log("deleting geofeatures");
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .delete(url + "/channels/" + this.$route.params.id + "/geofeatures", {
          body: idArray,
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success deleting geofeatures: ", response.bodyText);
            this.fetchGeofeatures(this.$route.params.id); // slow way. TODO: just remove from local projects []
            Helpers.showAlert("Geofeature deleted");
          },
          (response) => {
            // Fail
            console.log(
              "Failed DELETE to /channels/:channel/geofeatures",
              response.bodyText
            );
            if (response.body) {
              Helpers.showAlert("Error: " + response.bodyText);
            } else {
              Helpers.showAlert(
                "Failed DELETE to /channels/:channel/geofeatures"
              );
            }
          }
        );
    },
    toggleAllchecked(event) {
      if (event.target.checked) {
        // select all
        this.geofeatures.forEach((geofeature) => {
          if (
            this.checkedIDs.includes(geofeature.ID) === false &&
            (this.getSessionObj.userID === geofeature.OwnerID || this.isAdmin())
          ) {
            this.checkedIDs.push(geofeature.ID);
          }
        });
      } else {
        // unselect all
        this.checkedIDs = [];
      }
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
    handleAddGeofeatures(msg) {
      console.log(msg);
      this.fetchGeofeatures(this.channelID);
    },
  },
  created: function () {
    console.log("CREATED: /channel/:channel/geofeatures");
    Helpers.doViewCreation(this);

    messageBus.$on("event-geofeature-list-geofeatures-added", (message) =>
      this.handleAddGeofeatures(message)
    );
  },
  mounted: function () {
    this.fetchGeofeatures(this.channelID);
  },
  components: { SearchBox, JSONViewer },
};
</script>

<style scoped>
#tableset {
  max-height: 500px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
