<template>
  <div class="channeldetail container-fluid">

    <h1 class="page-header primary">
      Geofeature Details
    </h1>
    <p class="f3 fw5 tracked">Name: {{geofeature.Name}}</p>

    <div class="table-responsive table--no-card m-b-40">
        <table class="table table-borderless table-striped">

            <tbody>
                <tr>
                    <td>Name</td>
                    <td class="">
                      <span v-if="!canEdit()">{{geofeature.Name}}</span>
                      <span v-else>
                        <ClickToEdit :value=geofeature.Name :valueAuxData=geofeature.ID eventToEmit="event-geofeature-detail-name-change"></ClickToEdit>
                      </span>
                    </td>
                </tr>
                <tr>
                  <td>Description</td>
                    <td>
                      <span v-if="canEdit()">
                        <ClickToEdit :value=geofeature.Description :valueAuxData=geofeature.ID eventToEmit="event-geofeature-detail-description-change"/>
                      </span>
                      <span v-else>{{geofeature.Description}}</span>
                    </td>
                </tr>
                <tr>
                    <td>ID</td>
                    <td>{{geofeature.ID}}</td>
                </tr>
                <tr>
                    <td>Owner Name/ID</td>
                    <td>
                      <router-link class="gray hover-gold" v-bind:to="'/users/'+geofeature.OwnerID">
                        {{geofeature.OwnerName}}
                        <div class="f7">{{geofeature.OwnerID}}</div>
                      </router-link>
                    </td>
                </tr>
                <tr>
                    <td>Data</td>
                    <td>
                      <pre>{{geofeature.Data}}</pre>
                    </td>
                </tr>
                <tr>
                    <td>Created</td>
                    <td>{{geofeature.CreatedAt}}</td>
                </tr>
                <tr>
                    <td>Updated</td>
                    <td>{{geofeature.UpdatedAt}}</td>
                </tr>

            </tbody>
        </table>
      <JSONViewer :json="geofeature" />
    </div>

    <GeofeatureMap
      :geofeatures="geofeatureList"
      :mapCenter="geofeatureLocation"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import { messageBus } from "@/main";
import ClickToEdit from "@/components/ClickToEdit.vue";
import { mapGetters } from 'vuex';
import GeofeatureMap from '@/components/GeofeatureMap.vue';
import JSONViewer from '@/components/JSONViewer.vue';

export default {
  name: "GeofeatureDetail",
  data() {
    return {
      geofeature: {},
      channel: {},
      geofeatureLocation: {},
    };
  },
  computed: {
    ...mapGetters(['getSessionObj']),
    geofeatureList() {
      return [Helpers.parseGeofeature(this.geofeature)];
    },
  },
  methods: {
    fetchGeofeature(channelID, geofeatureID) {
      console.log("fetching geofeature with id: ", geofeatureID);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/channels/" + channelID + "/geofeatures/" + geofeatureID, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success getting /channels/" + channelID + "/geofeatures/" + geofeatureID, response.bodyText);
            this.geofeature = response.body;
            let parsedGeofeature = Helpers.parseGeofeature(this.geofeature);
            this.geofeatureLocation = {
              LatD: parsedGeofeature.location[0].lat,
              LngD: parsedGeofeature.location[0].lng,
            };
            this.fetchGeofeatureOwner();
          },
          response => {
            // Fail
            console.log("Failed to get /channels/" + channelID + "/geofeatures/" + geofeatureID, response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /channels/:channelID/goefeatures/:geofeatureID");
          }
        );
    }, // end fetch geofeature
    fetchChannel(id) {
      console.log("fetching channel with id: ", id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/channels/" + id, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success getting /channels:" + id, response.bodyText);
            this.channel = response.body;
          },
          response => {
            // Fail
            console.log("Failed to get /channels/:id ", response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /channels/:id");
          }
        );
    }, // end fetch channels
    handleEventGeofeatureDetailDescriptionChange(msg) {
      if(msg.valueAuxData === this.$route.params.geofeatureID && msg.valueAuxData === this.geofeature.ID) {
        this.geofeature.Description = msg.value;
        this.$set(this.channel, 'Description', msg.value);
        this.putGeofeature();
      }
    },
    handleEventGeofeatureDetailNameChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.geofeatureID &&
        msg.valueAuxData === this.geofeature.ID
      ) {
        this.geofeature.Name = msg.value;
        this.$set(this.channel, 'Name', msg.value);
        this.putGeofeature();
      }
    },
    putGeofeature() {
      console.log("updating geofeature : ", this.geofeature.Name);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL + "/channels/" + this.channel.ID + "/geofeatures/" + this.geofeature.ID;
      console.log("URL: ", url);

      this.$http
        .put(url, this.geofeature, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success updating geofeature", response.bodyText);
          },
          response => {
            // Fail
            console.log("Failed to update geofeature ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Failed to update geofeature " + response.bodyText
            );
          }
        );
    }, // end putChannel
    fetchGeofeatureOwner() {
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/users/" + this.geofeature.OwnerID, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success getting /users/+id", response.body);
            this.$set(this.geofeature, 'OwnerName', response.body.Name);
          },
          response => {
            // Fail
            console.log("Failed to get /users/+id ", response.body);
            Helpers.showAlert("ERROR: Couldn't retrieve /users/+id");
          }
        );
    },
    parseGeofeature() {
      return Helpers.parseGeofeature(this.geofeature).location[0];
    },
    canEdit() {
      // you can edit if the channel is
      //  (!channelLocked) AND (youAreOwner || youAreAdmin)
      const youAreOwner = (this.getSessionObj.userID === this.geofeature.OwnerID) ? true : false;
      const youAreAdmin = Helpers.isAdmin();

      if(!this.channel.IsLocked && (youAreOwner || youAreAdmin)) {
        return true;
      }
      return false;
    }
  },
  created: function() {
    Helpers.doViewCreation(this);
    this.fetchGeofeature(this.$route.params.channelID, this.$route.params.geofeatureID);
    this.fetchChannel(this.$route.params.channelID)

    messageBus.$on(
      "event-geofeature-detail-name-change",
      this.handleEventGeofeatureDetailNameChange
    );

    messageBus.$on(
      "event-geofeature-detail-description-change",
      this.handleEventGeofeatureDetailDescriptionChange
    );
  },
  mounted: function() {},
  components: { ClickToEdit, GeofeatureMap, JSONViewer },
};
</script>
