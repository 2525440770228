<template>
  <div class="channeldetail container-fluid">
    <h1 class="page-header primary">
      Channel Details
      <i
        v-if="channel.IsLocked"
        class="pl2 f3 black fa fa-lock"
        @click="toggleChannelLock(channel)"
      />
      <i
        v-else
        class="pl2 f3 black fa fa-unlock"
        @click="toggleChannelLock(channel)"
      />
    </h1>
    <p class="f3 fw5 tracked">Name: {{ channel.Name }}</p>

    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td class>
              <span v-if="!canEdit()">{{ channel.Name }}</span>
              <span v-else>
                <ClickToEdit
                  :value="channel.Name"
                  :valueAuxData="channel.ID"
                  eventToEmit="event-channel-detail-name-change"
                ></ClickToEdit>
              </span>
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              <span v-if="canEdit()">
                <ClickToEdit
                  :value="channel.Description"
                  :valueAuxData="channel.ID"
                  eventToEmit="event-channel-detail-description-change"
                />
              </span>
              <span v-else>{{ channel.Description }}</span>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{{ channel.ID }}</td>
          </tr>
          <tr>
            <td>Owner Name/ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/users/' + channel.OwnerID"
              >
                {{ channel.OwnerName }}
                <div class="f7">{{ channel.OwnerID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>IsLocked</td>
            <td>{{ channel.IsLocked }}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{{ channel.CreatedAt }}</td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>{{ channel.UpdatedAt }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <JSONViewer :json="channel" />

    <p class="f3 fw5 tracked mt4">Projects/Layers Using This Channel</p>
    <table class="table table-striped table-borderless table-sm mb4">
      <thead>
        <tr class="bg-gold b">
          <th class="th-sm">Project</th>
          <th class="th-sm">Project Layer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dep in projLayerDependencies" :key="dep.layerID">
          <td class>
            <router-link
              class="gray hover-gold"
              :to="'/projects/' + dep.projectID"
            >
              {{ dep.projectName }}
              <div class="f7">{{ dep.projectID }}</div>
            </router-link>
          </td>
          <td class>
            <router-link
              class="gray hover-gold"
              :to="{
                path: '/projects/' + dep.projectID + '/layers/' + dep.layerID,
                query: { projectName: dep.projectName },
              }"
            >
              {{ dep.layerName }}
              <div class="f7">{{ dep.layerID }}</div>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <GeofeatureList :channelID="this.$route.params.id" />
    <UploadCSV
      :channelID="this.$route.params.id"
      eventToEmit="event-geofeature-list-geofeatures-added"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import APIhelper from "@/api/apihelper.js";
import { messageBus } from "@/main";
import ClickToEdit from "@/components/ClickToEdit.vue";
import { mapGetters } from "vuex";
import GeofeatureList from "@/components/GeofeatureList.vue";
import UploadCSV from "@/components/UploadCSV.vue";
import JSONViewer from "@/components/JSONViewer.vue";

export default {
  name: "ChannelDetail",
  data() {
    return {
      channel: {},
      projLayerDependencies: [],
      geofeatures: [],
    };
  },
  computed: mapGetters(["getSessionObj", "getProjects"]),
  methods: {
    calcProjLayerDependencies() {
      this.projLayerDependencies = [];
      this.getProjects.forEach((p) => {
        APIhelper.fetchPrjLayers(p.ID).then((layers) => {
          layers.Items.forEach((l) => {
            if (l.ChannelID === this.channel.ID) {
              this.projLayerDependencies.push({
                projectName: p.Name,
                projectID: p.ID,
                layerName: l.Name,
                layerID: l.ID,
              });
            }
          });
        });
      });
      console.log("Proj/Layer dependencies: ", this.projLayerDependencies);
    },
    fetchChannel(id) {
      console.log("fetching channel with id: ", id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/channels/" + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success getting /channels:" + id, response.bodyText);
            this.channel = response.body;
            this.fetchChannelOwner();
            this.fetchGeofeatures(id);
            this.calcProjLayerDependencies();
          },
          (response) => {
            // Fail
            console.log("Failed to get /channels/:id ", response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /channels/:id");
          }
        );
    }, // end fetch channels
    fetchGeofeatures(id) {
      console.log("fetching geofeatures for channel with id: ", id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/channels/" + id + "/geofeatures", {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log(
              "Success getting /channels/" + id + "/geofeatures",
              response.bodyText
            );
            response.body.Items.forEach((element) => {
              let parsedGeofeature = Helpers.parseGeofeature(element);
              if (
                parsedGeofeature != null &&
                !this.geofeatures.includes(parsedGeofeature, 0)
              ) {
                this.geofeatures.push(parsedGeofeature);
              }
            });
            console.log("Triggering map update", this.geofeatureSet);
            messageBus.$emit("event-geofeatures-populated");
          },
          (response) => {
            // Fail
            console.log(
              "Error getting /channels/" + id + "/geofeatures",
              response.bodyText
            );
            Helpers.showAlert(
              "ERROR: Couldn't retrieve /channels/:id/geofeatures"
            );
          }
        );
    }, // end fetch channels
    fetchChannelOwner() {
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/users/" + this.channel.OwnerID, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success getting /users/+id", response.body);
            this.$set(this.channel, "OwnerName", response.body.Name);
            this.$forceUpdate();
          },
          (response) => {
            // Fail
            console.log("Failed to get /users/+id ", response.body);
            Helpers.showAlert("ERROR: Couldn't retrieve /users/+id");
          }
        );
    },
    canEdit() {
      // you can edit if the channel is
      //  (!channelLocked) AND (youAreOwner || youAreAdmin)
      const youAreOwner =
        this.getSessionObj.userID === this.channel.OwnerID ? true : false;
      const youAreAdmin = Helpers.isAdmin();

      if (!this.channel.IsLocked && (youAreOwner || youAreAdmin)) {
        return true;
      }
      return false;
    },
    putChannel() {
      console.log("updating channel name for channel: ", this.channel.Name);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url =
        this.getSessionObj.apiURL + "/channels/" + this.$route.params.id;
      console.log("URL: ", url);

      this.$http
        .put(url, this.channel, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success updating channel", response.bodyText);
          },
          (response) => {
            // Fail
            console.log("Failed to update channel name ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Failed to update layer " + response.bodyText
            );
          }
        );
    }, // end putChannel
    handleEventChannelDetailDescriptionChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.channel.ID
      ) {
        this.channel.Description = msg.value;
        this.$set(this.channel, "Description", msg.value);
        this.putChannel();
      }
    },
    handleEventChannelDetailNameChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.channel.ID
      ) {
        this.channel.Name = msg.value;
        this.$set(this.channel, "Name", msg.value);
        this.putChannel();
      }
    },
    toggleChannelLock(channel) {
      console.log("setting channel lock to ", channel.IsLocked);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      if (
        this.getSessionObj.userID != this.channel.OwnerID &&
        !this.isAdmin()
      ) {
        return;
      }

      let url = this.getSessionObj.apiURL;
      let requestBody = {
        IsLocked: !channel.IsLocked,
        Name: channel.Name,
        Description: channel.Description,
      };
      this.$http
        .put(url + "/channels/" + channel.ID, requestBody, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log(
              "Success setting channel lock status: ",
              response.bodyText
            );
            channel.IsLocked = !channel.IsLocked;
            // this.fetchChannels(); // slow way. TODO: just remove from local channels []
            if (channel.IsLocked) {
              Helpers.showAlert("Channel locked");
            } else {
              Helpers.showAlert("Channel unlocked");
            }
          },
          (response) => {
            // Fail
            console.log("Failed to PATCH to /channels:id", response.bodyText);
            if (response.body) {
              Helpers.showAlert("Error: " + response.bodyText);
            } else {
              Helpers.showAlert("Failed PATCH to /channels:id");
            }
          }
        );
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
  },
  created: function () {
    console.log("CREATED: /channel");
    Helpers.doViewCreation(this);
    this.fetchChannel(this.$route.params.id);

    messageBus.$on(
      "event-channel-detail-name-change",
      this.handleEventChannelDetailNameChange
    );

    messageBus.$on(
      "event-channel-detail-description-change",
      this.handleEventChannelDetailDescriptionChange
    );
  },
  mounted: function () {},
  components: { ClickToEdit, GeofeatureList, UploadCSV, JSONViewer },
};
</script>
