<template>
  <div class="uploadcsv">
    <h1 class="f3 fw5 tracked">Upload Geofeatures From CSV</h1>
    <vue-csv-import
      :key="vueCsvComponentKey"
      v-model="csvFile"
      :map-fields="mapfields"
      :canIgnore="true"
      :autoMatchFields="false"
      :autoMatchIgnoreCase="true"
      inputClass="hidefileinput"
    >
      <!-- inputClass="f6 dim primary b bg-white center ph3 pv2 mb2 br3 hidefileinput" -->
      <template slot="hasHeaders" slot-scope="{ headers, toggle }">
        <div>
          <input
            type="button"
            value="Select CSV File"
            @click="fileSelectDialog()"
            class="blue underline"
          />

          <span class="ml2 f6 tracked i" v-if="!selectedFileName.length"
            >No file selected</span
          >
          <span v-else class="ml2 f6 tracked i">
            {{ selectedFileName }}
          </span>
        </div>
        <div>
          <input
            type="checkbox"
            id="hasHeaders"
            :value="headers"
            @change="toggle"
          />
          <label for="hasHeaders" class="ml2">file has headers?</label>
        </div>
        <!-- <div>
          <input type="checkbox" id="hasTypeColumn" />
          <label for="hasTypeColumn" class="ml2">file has a "type" column?</label>
        </div>  -->
        <!-- <div>
          <input type="checkbox" id="hasAux" v-model="hasAuxData" />
          <label for="hasAux" class="ml2">file has auxillary data?</label>
          {{ mapfields }}
          <span class="ml2" v-if="hasAuxData"
            ># of fields:
            <select v-model="numAuxFieldsString" style="width: 50px">
              <option value="1" selected>1</option>
              <option
                v-for="i in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :key="i"
                :value="i"
              >
                {{ i }}
              </option>
            </select>
          </span>
        </div> -->
      </template>

      <template slot="error"> File type is invalid </template>

      <template slot="thead">
        All Fields Must be mapped to a Column (or choose Ignore). The Upload
        button won't appear until all fields are mapped.
        <tr class="bg-gold b">
          <th>GeoFeature Fields</th>
          <th>CSV Column</th>
        </tr>
      </template>

      <!-- <template slot="next" slot-scope="{ load }"> -->
      <template slot="next" slot-scope="{ load }">
        <!-- @click.prevent="load" -->
        <button
          @click.prevent="localLoad($event, load)"
          class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
        >
          Load File and Map Columns
        </button>
        (this loads the file in your web browser; Upload happens in another
        step)
      </template>
    </vue-csv-import>
    <div v-if="this.csvFile.length > 0">
      <router-link to="">
        <span>
          <span @click="performImport" class="mr4">
            Import
            <span class="f5">{{ this.csvFile.length }}</span> Geofeature(s)
          </span>
        </span>
      </router-link>
      <div class="table-responsive table--no-card m-b-40">
        <table class="table table-borderless table-striped">
          <tbody>
            <tr>
              <th class="th-sm" v-for="(field, idx) in mapfields" :key="idx">
                {{ field }}
              </th>
            </tr>
            <tr v-for="geofeature in this.csvFile" :key="geofeature.ID">
              <td v-for="(field, idx) in mapfields" :key="idx">
                {{ geofeature[field] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import { mapGetters } from "vuex";
import { VueCsvImport } from "vue-csv-import";
import { messageBus } from "@/main.js";
import Vue from "vue";

export default {
  name: "UploadCSV",
  data() {
    return {
      geofeatures: [],
      csvFile: [],
      selectedFileName: "",
      selectedFileElement: null,
      importAllCheckBox: false,
      mapfields: [],
      hasAuxData: false,
      numAuxFieldsString: "1",
      vueCsvComponentKey: 0, // used to force re-render of the vue-csv-component
    };
  },
  props: {
    channelID: String,
    eventToEmit: {
      type: String,
      required: false,
    },
  },
  computed: mapGetters(["getSessionObj"]),
  methods: {
    forceRerender() {
      this.vueCsvComponentKey += 1;
    },
    initializeOrResetMapFields() {
      this.mapfields = {
        latitude: "latitude",
        longitude: "longitude",
        elevation: "elevation",
        description: "description",
        name: "name",
        category: "category", // start of "Auxilliary Data fields"
        locationAccuracyM: "locationAccuracyM",
        timestamp: "timestamp",
      };
    },
    localLoad($event, load) {
      this.csvFile = []; // clear any previous loads
      // this.initializeOrResetMapFields(); // clear any previously added fields to map
      // add our aux new fields, if any, but first
      if (this.hasAuxData && parseInt(this.numAuxFieldsString) > 0) {
        for (let i = 1; i <= parseInt(this.numAuxFieldsString); i++) {
          Vue.set(this.mapfields, this.mapfields.length, "aux" + i);
        }
      }
      // this.forceRerender(); // needed to make the vue-csv-import component to update its new props, i.e. :map-fields="mapfields"
      this.$nextTick(() => {
        load($event); // this load is from the VueCsvImport library
      });
    },
    fileSelectDialog() {
      this.selectedFileElement.click();
    },
    performImport() {
      console.log(
        "uploading geofeatures from csv file to channel: ",
        this.channelID
      );
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url =
        this.getSessionObj.apiURL +
        "/channels/" +
        this.channelID +
        "/geofeatures";
      console.log("URL: ", url);

      let geofeaturesList = this.csvFile.map((geofeature) =>
        this.convertCSVtoGeofeature(geofeature)
      );
      console.log(geofeaturesList);

      this.$http
        .post(url, geofeaturesList, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log(
              "Success uploading geofeatures to channel",
              response.bodyText
            );
            if (this.eventToEmit) {
              messageBus.$emit(this.eventToEmit, response.body);
            }
          },
          (response) => {
            // Fail
            console.log(
              "Failed uploading geofeatures to channel ",
              response.bodyText
            );
            Helpers.showAlert(
              "ERROR: Failed to upload geofeatures to channel" +
                response.bodyText
            );
          }
        );
    },
    convertCSVtoGeofeature(csvElement) {
      let currentDate = new Date();
      let outputElement = {
        Data: {
          // category: csvElement.caetgory ? csvElement.category : "From CSV File",
        },
        Geometry: {
          Type: "PointHeight3D",
          Location: {
            LatD: Number(csvElement.latitude),
            LngD: Number(csvElement.longitude),
            ElevM: Number(
              csvElement.elevation == undefined ? 0 : csvElement.elevation
            ),
          },
          HeightM: 1,
        },
        Name: csvElement.name ? csvElement.name : "",
        Description:
          csvElement.description == undefined ? "" : csvElement.description,
        CreatedAt: currentDate.toISOString(),
        UpdatedAt: currentDate.toISOString(),
      };
      if (csvElement.category) {
        outputElement.Data["category"] = csvElement.category;
      }
      if (csvElement.locationAccuracyM) {
        outputElement.Data["locationAccuracyM"] = csvElement.locationAccuracyM;
      }
      if (csvElement.timestamp) {
        outputElement.Data["timestamp"] = csvElement.timestamp;
      }

      return outputElement;
    },
  },
  created: function () {
    console.log("CREATED: /channel/:channel/geofeatures");
    Helpers.doViewCreation(this);
    this.initializeOrResetMapFields();
  },
  mounted: function () {
    this.selectedFileElement = document.querySelector(".hidefileinput");

    this.selectedFileElement.addEventListener("change", (e) => {
      console.log("EEEEE: ", e);
      if (!e.target.files.length > 0) {
        console.warn("File selection returned NO result.");
        return;
      }
      this.selectedFileName = e.target.files[0].name;
      console.log("selectedFile: ", this.selectedFileName);
    });
  },
  components: { VueCsvImport },
};
</script>

<style>
.hidefileinput {
  visibility: hidden;
  display: none;
  size: 0px;
  border-style: solid;
}
</style>